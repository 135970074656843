import Vue from 'vue';
import { useAttrs } from 'vue';
import Router from 'vue-router';

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem('user'));
    if (data.user) {
        next();
    } else {
        next('/login');
    }
};

routes.push({
    path: '/coupons',
    component: () =>
        import ('../pages/app/coupons'),
    beforeEnter: requireAuth
});


routes.push({
    path: '/dashboard',
    component: () =>
        import ('../pages/app/home'),
    beforeEnter: requireAuth
});


Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});