import Vue from 'vue';
import { useAttrs } from 'vue';
import Router from 'vue-router';

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem('user'));
    if (data.user) {
        next();
    } else {
        next('/login');
    }
};




routes.push({
    path: '/coupons',
    component: () =>
        import ('../pages/app/coupons'),
    beforeEnter: requireAuth
});


routes.push({
    path: '/companies',
    component: () =>
        import ('../pages/companies'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/promotions/:company_id',
    component: () =>
        import ('../pages/promotions'),
    beforeEnter: requireAuth
});
routes.push({
    path: '/products/:company_id',
    component: () =>
        import ('../pages/products'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/coupons/:company_id',
    component: () =>
        import ('../pages/coupons'),
    beforeEnter: requireAuth
});


routes.push({
    path: '/qrcode',
    component: () =>
        import ('../pages/qrcode'),
    beforeEnter: requireAuth
});



routes.push({
    path: '/dashboard',
    component: () =>
        import ('../pages/app/coupons'),
    beforeEnter: requireAuth
});




Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});