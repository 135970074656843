import Vue from "vue";
import { useAttrs } from "vue";
import Router from "vue-router";
import admin from "./type_routes/admin";
import client from "./type_routes/client";
import businessmen from "./type_routes/businessmen";

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data.user) {
        next();
    } else {
        next("/login");
    }
};

const routes = [];
routes.push({ path: "/", name: "home", redirect: "/home" });
routes.push({
    path: "/home2",
    component: () =>
        import ("./pages/app/home"),
});

routes.push({
    path: "/register",
    component: () =>
        import ("./pages/app/register"),
});

routes.push({
    path: "/app/news",
    component: () =>
        import ("./pages/app/news"),
});

routes.push({
    path: "/app/news/list/:search",
    component: () =>
        import ("./pages/app/news"),
});

routes.push({
    path: "/app/news/:id",
    component: () =>
        import ("./pages/app/news/view"),
});


routes.push({
    path: "/app/jobs",
    component: () =>
        import ("./pages/app/jobs"),
});

routes.push({
    path: "/app/jobs/:id",
    component: () =>
        import ("./pages/app/jobs/view.vue"),
});

routes.push({
    path: "/tv",
    component: () =>
        import ("./pages/app/tv"),
});

routes.push({
    path: "/home",
    component: () =>
        import ("./pages/app/home2"),
});

routes.push({
    path: "/ao_vivo",
    component: () =>
        import ("./pages/app/ao_vivo"),
});

routes.push({
    path: "/entertainments",
    component: () =>
        import ("./pages/app/entertainments"),
});
routes.push({
    path: "/entertainment/:id",
    component: () =>
        import ("./pages/app/entertainments/view.vue"),
});

routes.push({
    path: "/product/:id",
    component: () =>
        import ("./pages/app/product/view.vue"),
});

routes.push({
    path: "/guides",
    component: () =>
        import ("./pages/app/guides"),
});
routes.push({
    path: "/guides/:id",
    component: () =>
        import ("./pages/app/guides/view.vue"),
});

routes.push({
    path: "/company/:id",
    component: () =>
        import ("./pages/app/company"),
});

routes.push({
    path: "/stores_and_discounts",
    component: () =>
        import ("./pages/app/stores_and_discounts"),
});

routes.push({
    path: "/videos_lista",
    component: () =>
        import ("./pages/app/videos_lista"),
});

routes.push({
    path: "/videos_lista/:id",
    component: () =>
        import ("./pages/app/videos_lista/view.vue"),
});

routes.push({
    path: "/login",

    component: () =>
        import ("./pages/login/login_page.vue"),
});
routes.push({
    path: "/forgot_password",
    component: () =>
        import ("./pages/forgot_password/forgot_password_page.vue"),
});

routes.push({
    path: "/Store",
    component: () =>
        import ("./pages/app/store"),
});

routes.push({
    path: "/order",
    component: () =>
        import ("./pages/app/order"),
});

routes.push({
    path: "/days",
    component: () =>
        import ("./pages/app/days"),
});

routes.push({
    path: "/cart",
    component: () =>
        import ("./pages/app/cart"),
});

let data = JSON.parse(localStorage.getItem("user"));

if (data) {
    const user = data.user;

    // console.log('user', user.type);

    routes.push({
        path: "/profile",
        component: () =>
            import ("./pages/profile/"),
        beforeEnter: requireAuth,
    });

    if (user.type == "admin") {
        admin.options.routes.forEach((element) => {
            routes.push(element);
        });
    }

    if (user.type == "client") {
        client.options.routes.forEach((element) => {
            routes.push(element);
        });
    }

    if (user.type == "businessmen") {
        businessmen.options.routes.forEach((element) => {
            routes.push(element);
        });
    }
}
routes.push({
    path: "*",
    component: () => {
        window.location.href = "/login";
    },
});

Vue.use(Router);
export default new Router({
    mode: "history",
    routes,
});