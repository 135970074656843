import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import Notifications from 'vue-notification'
import router from './routes'
import vuetify from './plugins/vuetify'
import VueSvgGauge from 'vue-svg-gauge'
import VueTheMask from 'vue-the-mask'
import VueSimpleAlert from "vue-simple-alert"
import * as VueGoogleMaps from "vue2-google-maps";


Vue.prototype.$user = JSON.parse(localStorage.getItem('user'))

const instance = axios.create({
    baseURL: "https://apiarujaclube.infous.com.br/api"
        // baseURL: "http://192.168.1.7/www/infous/arujaclube/api/public/api"
        // baseURL: "http://localhost:8000/api"
});

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDqpUKNUarozsMPNJvE_PB0XmCr-Lvn67A",
    },
});

var USER;
var NOTIFICATIONS = 0;
var POSTS_TOTAL = 0;

let token = localStorage.getItem("api_token");
if (token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;



    var data = JSON.parse(localStorage.getItem('user'));

    if (data.user) {
        USER = data.user;

        instance.defaults.baseURL += '/' + USER.type
    }

}


Vue.prototype.$http = instance;
Vue.prototype.$user = USER;


Vue.component('Layout', () =>
    import ('./components/Layout.vue'));

Vue.component('Layout-app', () =>
    import ('./components/Layout.vue'));

Vue.component('Upload', () =>
    import ('./components/Upload.vue'));

Vue.component('app-avatar', () =>
    import ('./components/Avatar.vue'));

Vue.component('banners-form', () =>
    import ('./pages/banners/form.vue'));

Vue.component('categories-form', () =>
    import ('./pages/categories/form.vue'));

Vue.component('companies-form', () =>
    import ('./pages/companies/form.vue'));

Vue.component('events-form', () =>
    import ('./pages/events/form.vue'));

Vue.component('jobs-form', () =>
    import ('./pages/jobs/form.vue'));

Vue.component('videos-form', () =>
    import ('./pages/videos/form.vue'));

Vue.component('photo', () =>
    import ('./components/inputImageUpload.vue'));


Vue.component('user-form', () =>
    import ('./pages/users/form.vue'));


Vue.component('promotions-form', () =>
    import ('./pages/promotions/form.vue'));

Vue.component('sliders', () =>
    import ('./components/sliders.vue'));

Vue.component('coupon-view', () =>
    import ('./components/Coupon.vue'));

Vue.component('number-field', () =>
    import ('./components/number-field.vue'));


Vue.use(Notifications);

Vue.use(VueSvgGauge);

Vue.use(VueTheMask);
Vue.use(VueSimpleAlert);

new Vue({
    router,
    vuetify,
    render: function(h) { return h(App) }
}).$mount('#app')